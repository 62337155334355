import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { isEmpty, map } from "lodash"
import "bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import { getCard, updateCard } from "store/actions"
//redux
import { useSelector, useDispatch } from "react-redux"
import { Col, Row, UncontrolledTooltip, Card, CardBody } from "reactstrap"
import CardModal from "./CardsModal"
import { getUsers as onGetUsers } from "store/users/actions"
import { getOffices } from "store/actions"
import { withTranslation } from "react-i18next"
import MiniCards from "pages/Dashboard/mini-cards"
import { checkPermission } from "helpers/functions"
import moment from "moment"

function CardList(props) {
  //meta title

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [cards, setCard] = useState(null)
  const [show, setShow] = useState(false)

  const dispatch = useDispatch()

  const {
    cardData,
    error,
    success,
    nextPage,
    currentPage,
    previousPage,
    total,
    limit,
    loading,
  } = useSelector(state => state.cards)
  const userRoles = useSelector(state => state.Login.user?.roles)
  const { user } = useSelector(state => state.Login)
  const { users } = useSelector(state => state.users)
  const { officesData } = useSelector(state => state.offices)

  const [filter, setFilter] = useState({
    page: 1,
    limit: 20,
    office: "",
    customerId: "",
    from: moment().startOf("month"),
    to: moment().endOf("month"),
    status: "active",
  })

  console.log("total+++++++++++++++++", total)

  useEffect(() => {
    dispatch(getCard(filter))
    dispatch(onGetUsers({ limit: 10000, page: 1, accountType: "customer" }))
    dispatch(getOffices({ limit: 10000, page: 1, status: "active" }))
  }, [dispatch, filter])

  useEffect(() => {
    if (!isEmpty(cardData) && !!isEdit) {
      setIsEdit(false)
    }
  }, [cardData])

  const [miniCards, setMiniCards] = useState([
    {
      title: props.t("total number of card"),
      iconClass: "bx-check-circle",
      text: 0,
    },
    {
      title: props.t("total price"),
      iconClass: "dripicons-user-group",
      text: 0,
    },
    {
      title: props.t("total commission"),
      iconClass: "dripicons-user-group",
      text: 0,
    },
  ])

  useEffect(() => {
    if (cardData != undefined) {
      setMiniCards([
        {
          title: props.t("total number of card"),
          iconClass: "bx-check-circle",
          text: total,
        },
        {
          title: props.t("total price"),
          iconClass: "dripicons-user-group",
          text: cardData?.reduce((total, item) => total + item.price, 0),
        },
        {
          title: props.t("total commission"),
          iconClass: "dripicons-user-group",
          text:
            cardData
              ?.reduce((total, item) => {
                if (item.price && item.office?.commissionRate) {
                  return total + (item.price * item.office.commissionRate) / 100
                }
                return total
              }, 0)
              .toFixed(2) + " ",
        },
      ])
    }
  }, [cardData])

  const toggle = () => {
    if (modal) {
      setModal(false)
      setCard(null)
    } else {
      setModal(true)
    }
  }

  const toggleShow = () => {
    setShow(!show)
  }

  const handleOrderClick = arg => {
    const cards = arg
    setCard({
      ...cards,
    })

    setIsEdit(true)

    toggle()
  }

  console.log("---carddd---", cards)
  //delete order
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = cards => {
    setCard(cards)
    setDeleteModal(true)
  }

  const handleDeleteCard = () => {
    if (cards.id) {
      dispatch(updateCard({ id: cards.id, status: "archived" }))
      setDeleteModal(false)
      dispatch(getCard(filter))
    }
  }
  const handleOrderClicks = () => {
    setIsEdit(false)
    toggle()
  }

  const columns = useMemo(
    () => [
      {
        Header: props.t("Card ID"),
        accessor: "id",
        disableFilters: true,
        accessor: cellProps => {
          return <div>{cellProps.id}</div>
        },
      },

      {
        Header: props.t("Card Number"),
        accessor: "code",
        disableFilters: true,
        accessor: cellProps => {
          return <span>{cellProps.code ?? "----"}</span>
        },
      },
      {
        Header: props.t("Customer Name"),
        accessor: "customer",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <span>
              {cellProps.customer
                ? cellProps.customer?.firstname +
                  " " +
                  cellProps.customer?.lastname
                : "----"}
            </span>
          )
        },
      },
      {
        Header: props.t("Card Type"),
        accessor: "code",
        disableFilters: true,
        accessor: cellProps => {
          return <span>{cellProps.cardType?.name || "----"}</span>
        },
      },

      {
        Header: props.t("Join Date"),
        accessor: "createdAt",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <span>{moment(cellProps.createdAt).format("MMM DD . YYYY")}</span>
          )
        },
      },
      {
        Header: props.t("Card Price"),
        accessor: "code",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <span>
              {cellProps.currency
                ? cellProps.price + " " + cellProps.currency
                : cellProps.price || "----"}
            </span>
          )
        },
      },

      {
        Header: props.t("Payment Method"),
        accessor: "code",
        disableFilters: true,
        accessor: cellProps => {
          return <span>{cellProps.paymentMethod?.nameEn || "----"}</span>
        },
      },
      {
        Header: props.t("Payment Status"),
        accessor: "paymentStatus",
        disableFilters: true,
        accessor: cellProps => {
          return <span>{props.t(cellProps.paymentStatus) || "----"}</span>
        },
      },
      {
        Header: props.t("Agency"),
        accessor: "Agency",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <span>{cellProps.office ? cellProps.office?.name : "----"}</span>
          )
        },
      },
      {
        Header: props.t("Agency User"),
        accessor: "code",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <span>
              {cellProps.createdBy
                ? cellProps.createdBy?.firstname +
                  " " +
                  cellProps.createdBy?.lastname
                : "----"}
            </span>
          )
        },
      },
      {
        Header: props.t("Agency Commission"),
        accessor: "code",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <span>
              {cellProps.price && cellProps.office
                ? (cellProps.price * cellProps.office?.commissionRate) / 100 +
                  " " +
                  cellProps.currency
                : "----" || "--"}
            </span>
          )
        },
      },

      {
        Header: props.t("Action"),
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="text-center">
              {checkPermission(userRoles, ["Card", "Card", "Update"]) && (
                <Link
                  to={`/show-card/${cellProps.row?.original?.id}`}
                  className="text-tip"
                >
                  <i
                    className="bx bx-bullseye mt-1 font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    View
                  </UncontrolledTooltip>
                </Link>
              )}
              {checkPermission(userRoles, ["Card", "Card", "Show"]) && (
                <Link
                  to="#"
                  className="text-tip"
                  onClick={() => {
                    const cardData = cellProps.row.original
                    handleOrderClick(cardData)
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    {props.t("Edit")}
                  </UncontrolledTooltip>
                </Link>
              )}
            </div>
          )
        },
      },
    ],
    []
  )

  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }

  const handlePartner = partner => {
    setFilter(prevFilter => {
      return { ...prevFilter, customerId: partner.value }
    })
  }
  const handleOffice = partner => {
    setFilter(prevFilter => {
      return { ...prevFilter, officeId: partner.value }
    })
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }

  const handleDateFromTo = date => {
    if (!moment(date?.endDate).diff(date?.startDate, "days")) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.startDate).add(1, "days").format("Y-MM-DD"),
        }
      })
    } else {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.endDate).format("Y-MM-DD"),
        }
      })
    }
  }

  return (
    <React.Fragment>
      <CardModal modal={modal} toggle={toggle} isEdit={isEdit} card={cards} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCard}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Cards")}
            breadcrumbItem={props.t("Cards List")}
          />
          <Row>
            <Col xl="10">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={cardData}
                    //isGlobalFilter={true}
                    // isAddOptions={true}
                    isAddOptions={checkPermission(userRoles, [
                      "Card",
                      "Card",
                      "Create",
                    ])}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={100}
                    filterDate={true}
                    // isCustomerFilter={true}
                    setDateFilter={handleDateFromTo}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    setPartner={handlePartner}
                    partners={users}
                    isCustomerFilter={true}
                    offices={officesData.filter(
                      item => item.status == "active"
                    )}
                    setOffice={handleOffice}
                    isOfficeFilter={true}
                    resetAllCardOption={true}
                    currentPage={currentPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
CardList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(CardList)
